<template>
  <el-row>
    <el-form-item prop="data">
      <el-select
        filterable
        :loading="isFetching"
        v-model="value.data"
        placeholder="Select web page"
        style="width: 100%"
      >
        <el-option
          v-for="webPage in webPages"
          :key="webPage.id"
          :label="webPage.name"
          :value="webPage.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="hash">
      <el-input v-model="value.hash">
        <template slot="prepend">
          <el-tooltip
            content="This value will get render when the microsite url got generated, this will enable the jump to content feature."
            placement="top"
          >
            <span>
              Hash
              <i style="margin-left: 5px" class="el-icon-info"></i>
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </el-form-item>

    <el-checkbox v-model="value.downloadAsPdf" style="margin-right: 10px; margin-left: 0px">
      Download as pdf
    </el-checkbox>
  </el-row>
</template>

<script>
import { graph } from "@/store/api";
import gql from "graphql-tag";
import _ from "lodash";

import { BUTTON_TYPES } from "./Index";

const FIELDS = ["active", "type", "text", "hash", "params", "data", "synonym", "downloadAsPdf"];

export default {
  name: "ButtonTypeMicrosite",
  props: ["value"],
  data() {
    return {
      isFetching: false,
      webPages: [],
    };
  },
  methods: {
    /**
     * @description Fetch all webpages
     * @return {void}
     */
    fetch() {
      if (!this.isFetching) {
        this.isFetching = true;
        graph
          .query({
            query: gql`
              query {
                webPageEditorAPI {
                  fetch
                }
              }
            `,
            fetchPolicy: "network-only",
          })
          .then((response) => {
            this.webPages = _.get(response, "data.webPageEditorAPI.fetch", []);
          })
          .catch((error) =>
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Failed to fetch web pages.`,
            })
          )
          .finally(() => (this.isFetching = false));
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler(coming, prev) {
        this.value.type = BUTTON_TYPES[this.$options.name].name;
        for (let key in this.value) {
          if (!_.includes(FIELDS, key)) {
            delete this.value[key];
          }
        }
      },
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
