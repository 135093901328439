<template>
  <div style="position: absolute; top: 50%; transform: translateY(-50%); right: 4px">
    <el-button
      v-if="button.event === 'goto'"
      :plain="true"
      round
      icon="el-icon-d-arrow-right"
      size="mini"
      @click="selectNode({ type: 'content', id: button.data })"
    />
    <el-button
      v-else-if="button.event === 'capture'"
      :plain="true"
      round
      icon="el-icon-d-arrow-right"
      size="mini"
      @click="selectNode({ type: 'content', id: button.data.next })"
    />
    <el-button
      round
      :plain="true"
      v-else-if="button.url && button.type !== 'microsite'"
      size="mini"
    >
      <a rel="noopener" :href="button.url" target="_blank" class="el-icon-d-arrow-right" />
    </el-button>
  </div>
</template>

<script>
export default {
  name: "ButtonShortcutAction",
  props: ["button"],
  methods: {
    selectNode({ type, id }) {
      let nodeId = id;
      if (id.constructor === Object && id.event === "goto") {
        nodeId = id.data;
      }
      this.$store.dispatch("SELECT_NODE", { type, id: nodeId });
    },
  },
};
</script>
