export const BUTTON_TYPES = {
  ButtonTypeEvent: {
    label: "Action",
    name: "event",
  },
  ButtonTypeWebsite: {
    label: "Website",
    name: "website",
  },
  ButtonTypePhone: {
    label: "Phone",
    name: "phone",
  },
  ButtonTypeMicrosite: {
    label: "Microsite",
    name: "microsite",
  },
};
